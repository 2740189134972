<template>
  <div>
    <video ref="video" style="display:none;"></video>
    <canvas ref="canvas"></canvas>
    <div class="code">デコードされた内容: {{ decodedContent }}</div>
  </div>
</template>

<script>
import jsQR from 'jsqr';


export default {
  name: "CameraView",
  data() {

    return {
      decodedContent: '',
    }
  },
  mounted() {
    this.setupCamera();
    window.addEventListener('resize', this.handleResize);
    this.initialWidth = Math.min(window.innerWidth -20, 400);
    this.handleResize(); // 初期サイズ調整
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    if (this.$refs.video.srcObject) {
      this.$refs.video.srcObject.getTracks().forEach(track => track.stop());
    }
  },
  methods: {
    setupCamera() {
      navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment", aspectRatio: 1.777 } })
        .then(stream => {
          this.$refs.video.srcObject = stream;
          this.$refs.video.play();
          requestAnimationFrame(this.tick);
        }).catch(error => {
          console.error('カメラアクセスに失敗しました:', error);
        });
    },
    tick() {
      if (this.$refs.video.readyState === this.$refs.video.HAVE_ENOUGH_DATA) {
        this.adjustCanvasSize();
        this.$refs.canvas.getContext('2d').drawImage(this.$refs.video, 0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
        const imageData = this.$refs.canvas.getContext('2d').getImageData(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert",
        });
        if (code) {
          this.decodedContent = code.data;
          this.$router.push({ name: 'result', params: { decodedContent: this.decodedContent } });
          return
        }
      }
      requestAnimationFrame(this.tick);
    },
    handleResize() {
      this.adjustCanvasSize();
    },
    adjustCanvasSize() {
      const videoAspectRatio = this.$refs.video.videoWidth / this.$refs.video.videoHeight;
      let newHeight = this.initialWidth / videoAspectRatio; // 初期幅に基づいた新しい高さを計算

      if (newHeight > window.innerHeight) {
        newHeight = window.innerHeight;
        this.initialWidth = newHeight * videoAspectRatio;
      }

      this.$refs.canvas.width = this.initialWidth;
      this.$refs.canvas.height = newHeight;
    }
  }
}
</script>

<style>
  html, body {
    height: 100%; /* 全画面の高さを確保 */
    margin: 0; /* マージンをゼロに設定 */
    display: flex; /* Flexboxを利用 */
    justify-content: center; /* 水平方向中央揃え */
    align-items: center; /* 垂直方向中央揃え */
  }


  .code{
  display: none;
  }
</style>
