<template>
  <div class="start-view">
    <video ref="videoElement" autoplay playsinline style="width: 100%; height: 100%; display: none;"></video>
    <div  class="start-button" @click="this.$router.push({ name: 'camera' })">読み取りSTART</div>
  </div>
</template>

<script>

export default {
  name: 'StartView'
}
</script>

<style scoped>
.start-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
}

.start-button, .stop-button {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  background-color: #41747a;
  color: white;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  border-radius: 25px;
  padding: 10px;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
  border: none;
  transition: background-color 0.3s, transform 0.3s;
}

.start-button:hover, .stop-button:hover {
  background-color: #35a3a8;
  transform: translateY(-2px);
}

.stop-button {
  background-color: #d9534f;
}

.stop-button:hover {
  background-color: #c9302c;
}
</style>
