import { createRouter, createWebHistory } from 'vue-router'; // Vue 3のルーターインポート方法
import StartView from '@/components/StartView.vue';
import CameraView from '@/components/CameraView.vue';
import ResultView from '@/components/ResultView.vue';

const router = createRouter({
  history: createWebHistory(), // historyモードの設定
  routes: [
    {
      path: '/',
      name: 'home',
      component: StartView
    },
    {
        path: '/camera',
        name: 'camera',
        component: CameraView
    },
    {
        path: '/result/:decodedContent',
        name: 'result',
        component: ResultView
    }
  ]
});

export default router;
