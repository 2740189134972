<template>
  <div>
    <h1>【判定結果】</h1>
    <h2>あなたの <br> 読み取られ件数は<br> <span>{{ count }}</span>件です</h2>
    <div  class="start-button" @click="this.$router.push({ name: 'camera' })">戻る</div>
  </div>
</template>

<script>
export default {
  name: "ResultView",
  data() {
    return {
      count: null
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await fetch(`https://f05zmt9g0m.execute-api.ap-northeast-1.amazonaws.com/staging/gacha?uuid=${this.$route.params.decodedContent}`);
        if (!response.ok) throw new Error('サーバーエラー');
        const data = await response.json();
        console.log(data.data)
        this.count = data.data;
      } catch (error) {
        console.error('APIの取得に失敗しました:', error);
        alert('データのロードに失敗しました。'); // ユーザーにエラーを通知
      }
    }
  },
  mounted() {
    this.fetchData(); // コンポーネントがマウントされたときに fetchData を実行
  }
};
</script>

<style scoped>
span {
    font-size: 46px; 
}

h1, h2 {
  text-align: center; /* タイトルと件数を中央揃え */
}

.start-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
}

.start-button, .stop-button {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  background-color: #41747a;
  color: white;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  border-radius: 25px;
  padding: 10px;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
  border: none;
  transition: background-color 0.3s, transform 0.3s;
}

.start-button:hover, .stop-button:hover {
  background-color: #35a3a8;
  transform: translateY(-2px);
}
</style>
